<template>
  <div class="stock">
    <van-nav-bar title="基金" />
    <div class="layout" v-for="item in list" :key="item.id">
      <div class="layout-img">
        <img :src="item.images" alt="">
      </div>
      <div class="padded-15">
        <div class="title">{{item.title}}</div>
        <div class="padded-t-15 padded-b-15">
          <van-row>
            <van-col span="8" style="text-align: left">
              <div class="text-black font-size-16 font-weight">￥{{item.price}}</div>
              <div class="font-size-12 text-grey padded-t-5">每份价格</div>
            </van-col>
            <van-col span="8" style="text-align: center">
              <span class="text-black font-size-16 font-weight">{{item.fenhong}}</span>
              <span class="font-size-12">元/天</span>
              <div class="font-size-12 text-grey padded-t-5">每日分红</div>
            </van-col>
            <van-col span="8" style="text-align: right">
              <div class="text-danger font-size-16">{{Number(item.proportion).toFixed(2)}}%</div>
              <div class="font-size-12 text-grey padded-t-5">分红比例</div>
            </van-col>
          </van-row>
        </div>
        <div class="btn">
          <van-button type="danger" block @click="detail(item)">立即购买</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductList } from '@/utils/api.js'
export default {
  name: 'fund',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.getProduct()
  },
  methods: {
    getProduct () {
      getProductList(2).then(res => {
        this.list = res.data
      })
    },
    detail (item) {
      this.$router.push({
        name: 'info',
        query: {
          id: item.id,
          type: item.productType
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .stock {
    background: url(~@/assets/images/bg.png) center no-repeat;
    background-size: 100% 100%;
    /*height: 100vh;*/
    .layout {
      background: #ffffff;
      margin: 15px;
      border-radius: 10px;
      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 148px;
        width: 100%;
      }
      .title {
        color: #2a2a2a;
        font-size: 16px;
        font-weight: 700;
      }
      .btn .van-button--danger{
        //background: #f60;
        border-radius: 8px;
        height: 40px;
      }
    }
  }
</style>
